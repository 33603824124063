// Use SQL script to generate the following:
/*

WITH cte_roles
AS (
     SELECT replace(ROLE, ' ', '') + ': ''' + ROLE + ''',' role_data
     FROM dbo.roles
     )
SELECT 'export const roles = {' + (
          SELECT role_data + ''
          FROM cte_roles
          FOR XML path('')
          ) + '};' result

*/

export const roles = {
  ALL: 'ALL',
  Admin: 'Admin',
  User: 'User',
  WarehouseLead: 'Warehouse Lead',
  WarehouseManager: 'Warehouse Manager',
  SalesConsultant: 'Sales Consultant',
  SalesOperations: 'Sales Operations',
  SalesManager: 'Sales Manager',
  Warehouse: 'Warehouse',
  Accounting: 'Accounting',
  AccountingLead: 'Accounting Lead',
  AccountingManager: 'Accounting Manager',
  SCM: 'SCM',
  SCMLead: 'SCM Lead',
  SCMManager: 'SCM Manager',
  IT: 'IT',
  Leadership: 'Leadership',
  CCAdvisor: 'CC Advisor',
  CCLead: 'CC Lead',
  CCManager: 'CC Manager',
  AccountManagement: 'Account Management',
  Marketing: 'Marketing',
  MarketingManager: 'Marketing Manager',
  customerService: 'Customer Service',
};

const superAdminRoles = [roles.ALL, roles.Admin, roles.IT, roles.Leadership];

const salesRoles = [
  roles.SalesConsultant,
  roles.SalesOperations,
  roles.SalesManager,
  roles.AccountManagement,
];

const accountingRoles = [
  roles.Accounting,
  roles.AccountingLead,
  roles.AccountingManager,
];

const supplyChainRoles = [roles.SCM, roles.SCMLead, roles.SCMManager];
const marketingRoles = [roles.Marketing, roles.MarketingManager];

export const contactRoles = {
  CustomsBroker: 'Customs Broker',
  FreightBroker: 'Freight Broker',
};

export const groups = {
  unitCostVisible: [
    ...superAdminRoles,
    ...salesRoles,
    ...accountingRoles,
    ...supplyChainRoles,
  ],
  productionWarehouseVisible: [
    ...superAdminRoles,
    roles.SalesOperations,
    roles.SalesManager,
  ],
  rankDateVisible: [
    ...superAdminRoles,
    roles.SalesManager,
    roles.SalesOperations,
  ],
  authorizedToReleasePaymentRequiredFlag: [
    ...accountingRoles,
    ...superAdminRoles,
    roles.CCLead,
    roles.CCManager,
    roles.SalesManager,
    roles.SalesOperations,
  ],
  editVendorSupplierDocumentLinks: [
    ...supplyChainRoles,
    ...superAdminRoles,
    roles.SalesManager,
  ],
  seeAllPaymentTypes: [...superAdminRoles, ...accountingRoles],
  editVendorSupplierNotes: [
    ...supplyChainRoles,
    ...superAdminRoles,
    roles.SalesManager,
  ],
  errorLogAdmin: [
    roles.ALL,
    roles.Admin,
    roles.CCLead,
    roles.CCManager,
    roles.SalesOperations,
    roles.SalesManager,
    roles.SCMLead,
    roles.SCMManager,
    roles.WarehouseManager,
  ],
  errorListSalesSupport: [
    roles.SalesConsultant,
    roles.SalesManager,
    roles.SalesOperations,
  ],
  errorListWarehouse: [
    roles.WarehouseLead,
    roles.WarehouseManager,
    roles.Warehouse,
  ],
  errorListCustomerCare: [roles.CCAdvisor, roles.CCLead, roles.CCManager],
  marketingRoles: [roles.Marketing, roles.MarketingManager],
  supplyChainVisible: [
    ...supplyChainRoles,
    ...superAdminRoles,
    ...marketingRoles,
  ],
};

export const users = {
  houseAccount: 'CUSTOMER.SERVICE',
  accountManagement: 'ACCOUNT.MANAGEMENT',
};
